<template>
  <div class="main">
    <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules" hideRequiredMark>
      <!-- <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="loginErrorInfo" closable
        :after-close="handleCloseLoginError" /> -->
      <a-form-model-item prop="accountNo" label="User name" :colon="false" has-feedback>
        <a-input v-model="form.accountNo" size="large" placeholder="">
          <!-- <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password" label="Password" :colon="false" has-feedback>
        <a-input-password v-model="form.password" size="large" placeholder="">
          <!-- <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
        </a-input-password>
      </a-form-model-item>
      <a-row :gutter="16" v-if="captchaEnabled">
        <a-col class="gutter-row" :span="16">
          <a-form-model-item prop="code">
            <a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码">
              <a-icon slot="prefix" type="security-scan" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <img class="getCaptcha" :src="codeUrl" @click="getCode">
        </a-col>
      </a-row>
      <!-- <a-form-model-item prop="rememberMe">
        <a-checkbox :checked="form.rememberMe" @change="rememberMe">记住密码</a-checkbox>
      </a-form-model-item> -->
      <a-form-item style="margin-top: 24px">
        <a-button
          size="large"
          htmlType="submit"
          class="login-button"
          :loading="logining"
          :disabled="logining"
          @click="handleSubmit"
          >Sign in</a-button
        >
      </a-form-item>
      <div class="user-login-other">
        <!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'

export default {
  components: {},
  data() {
    return {
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        accountNo: '',
        password: '',
        code: undefined,
        uuid: '',
        // rememberMe: false
      },
      rules: {
        accountNo: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: true,
      redirect: undefined,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  created() {
    // this.getStorage()
    if (this.captchaEnabled) {
      this.getCode()
    }
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.captchaEnabled = res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled
        if (this.captchaEnabled) {
          this.codeUrl = 'data:image/gif;base64,' + res.data.img
          this.form.uuid = res.data.uuid
        }
      })
    },
    getStorage() {
      const accountNo = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (accountNo) {
        this.form = {
          accountNo: accountNo,
          password: password,
          rememberMe: rememberMe,
        }
      }
    },
    // rememberMe(e) {
    //   this.form.rememberMe = e.target.checked
    // },
    ...mapActions(['Login', 'Logout']),
    handleSubmit() {
      this.logining = true
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.form.rememberMe) {
          //   storage.set(LOGIN_USERNAME, this.form.accountNo)
          //   storage.set(LOGIN_PASSWORD, this.form.password)
          //   storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
          // } else {
          //   storage.remove(LOGIN_USERNAME)
          //   storage.remove(LOGIN_PASSWORD)
          //   storage.remove(LOGIN_REMEMBERME)
          // }
          this.$store
            .dispatch('Login', this.form)
            .then((res) => {
              this.$router.push({ path: this.redirect || '/' }).catch(() => {})
            })
            .catch((err) => {
              console.log('err', err)
              this.loading = false
              setTimeout(() => {
                this.logining = false
              }, 1000)
              if (this.captchaEnabled) {
                this.getCode()
              }
            })
          this.Login(this.form)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              this.logining = false
            })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`,
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  // background-color: red;
  background-color: #fff;
  border: 2px solid #d3d3d3;
  border-radius: 0.5em;
  margin: 1rem auto;
  padding: 10px 1rem;
  text-align: left;
  vertical-align: middle;
  width: 400px;
  ::v-deep .user-layout-login {
    .ant-form-item {
      margin-bottom: 10px !important;

      .ant-form-item-label {
        font-weight: 600 !important;
        color: #212529 !important;
        font-family: Poppins, sans-serif;
        font-size: 16px !important;
      }
    }
    .ant-form-item-control-wrapper {
      .ant-form-explain {
        display: none !important;
      }
    }
  }
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    border-radius: 0.25rem;
    // width: 100%;
    background-color: #7f62ad;
    border-color: #7f62ad;
    color: #fff;
    font-weight: bold !important;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .register {
      float: right;
    }
  }
}
</style>
